import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../_alert';
import { AdaptersService } from '../adapters.service';
import { Globals } from '../global.constants';

@Component({
  selector: 'app-add-funds',
  templateUrl: './add-funds.component.html',
  styleUrls: ['./add-funds.component.scss']
})
export class AddFundsComponent implements OnInit, OnDestroy {

  @ViewChild('amountInput') amountInput: ElementRef;

  menu: any;
  params: any;
  isYESPAY: any;
  userData = {} as any;

  virtualCardObj: any;
  physicalCardObj: any;

  isIOSDevice: boolean;
  isAndroidMobile: boolean;

  isSaving = false;
  isMobile = false;
  addFundsModal = false;

  showMenu = false;
  showQrPage = false;
  showAmountPage = true;
  showPaymentLoader = false;
  showTransactionResult = false;

  qrString = "";
  merchantRequestId: string;
  gatewayTransactionId: string;
  paymentConfirmationMessage: string = "";
  downloadAPPIOSUrl: string = "https://apps.apple.com/in/app/yes-pay-wallet/id1074725312";

  id: any;
  txnDate: any;
  txnData: any;
  stsChkStop: any;
  stsChkStart: any;
  downloadApp: any;
  downloadAppIOS: any;

  menuList: any = [];
  txnStatusList: string[] = ['SUCCESS', 'FAIL'];
  txnStatusInProcessList: string[] = ['PENDING', 'NOT_ATTEMPTED'];

  amountForm = this.formBuilder.group({
    amount: ['', [Validators.required]]
  });

  constructor(private router: Router,
    private formBuilder: UntypedFormBuilder,
    private adaptersService: AdaptersService,
    protected activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    protected renderer: Renderer2,
    protected elementRef: ElementRef,
    private sanitizer: DomSanitizer) {
    this.isMobile = Globals.isMobile();
    this.isAndroidMobile = Globals.isAndroidMobile();
    this.isIOSDevice = Globals.isIOSDevice();
  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      this.params = params;
    });

    // If menu object is empty or on page refresh
    if (!this.menu) {
      if (this.params.id) {
        this.getMenu(this.params.id);
      }
    }

    // Navigated from menu page
    else {
      this.setIsYesPayFlag();
    }
  }

  setIsYesPayFlag() {
    if (this.menu.menuIdentifier === 'YESPAY') this.isYESPAY = true; else this.isYESPAY = false;

    if (!this.isAndroidMobile) {
      if(this.menu.menuIdentifier === 'CHALO'){
        this.fetchUserCardDetails();
      }
      this.showAmountPage = false;
      if (this.isIOSDevice) {
        this.downloadApp = false;
        this.downloadAppIOS = true;
        if (this.isYESPAY) this.dashboard();
        else if (!this.isYESPAY) this.fetchUserCardDetails();
      } else {
        this.qrString = "https://onelink.to/5rmjr7";
        this.downloadAppIOS = false;
        this.downloadApp = true;
      }
    } else {
      if (this.isYESPAY) this.dashboard();
      else if (!this.isYESPAY) this.fetchUserCardDetails();
    }
  }

  amountChange(input: any) {
    const value = input.value;
    this.qrString = "";
    if (value && !/^[0-9]+(\.[0-9]{0,2})?$/.test(value)) {
      input.value = input.value.toString().slice(0, -1);
    } else if (this.userData && (Number(value) > this.userData.remainingWalletBalanceLimit)) {
      this.amountForm.get('amount').markAsDirty();
      this.amountInput.nativeElement.blur();
      this.amountInput.nativeElement.focus();
    }
  }

  sanitize() {
    return this.sanitizer.bypassSecurityTrustUrl(this.qrString);
  }

  getUpiIntent(from: string) {
    this.amountForm.get('amount').markAsTouched();
    if (this.amountForm.valid) {
      if (Number(this.amountForm.value.amount) > this.userData.remainingWalletBalanceLimit) {
        this.alertService.error("Amount cannot be greater than remaining balance limit")
        return;
      }
      this.isSaving = true;
      this.amountForm.get('amount').setValue(Number(this.amountForm.value.amount).toFixed(3).replace(/\.(\d\d)\d?$/, '.$1'));
      const reqObj = {
        "amount": this.amountForm.value.amount,
      } as any;
      if (!this.isYESPAY && this.menu && this.menu.ypsorPrepaidProgram && this.menu.ypsorPrepaidProgram.programIdentifier) {
        reqObj.programId = this.menu.ypsorPrepaidProgram.programIdentifier;
      }
      let kycStatus = localStorage.getItem("kycStatus");
      if (kycStatus) {
        reqObj.kycStatus = kycStatus;
      } else {
        this.isSaving = false;
        this.alertService.error("Unable to process request. Please try again later.");
        return;
      }
      this.paymentConfirmationMessage = 'You will be redirected to the selected UPI application';
      this.adaptersService.registerIntent(reqObj).then((res: any) => {
        this.isSaving = false;
        if (res) {
          this.merchantRequestId = res.payload ? res.payload.merchantRequestId : "";
          this.gatewayTransactionId = res.payload ? res.payload.gatewayTransactionId : "";
          this.qrString = res.qrString;
          if (from === "QR")
            this.goToQrPage()
        }
      }, (error: any) => {
        window.location.reload();
      })
    }
  }

  goToQrPage() {
    this.showAmountPage = false;
    this.showQrPage = true;
    this.checkTxnStatus("QR");
  }

  checkTxnStatus(type: string) {
    let txnChkTime = 1.5
    if (type != "QR") {
      this.showPaymentLoader = true
      txnChkTime = 1
    }
    this.stsChkStart = setTimeout(() => {
      this.id = setInterval(() => {
        this.getTxnStatus();
      }, 3000);
      this.stsChkStop = setTimeout(() => {
        this.StopChkStatus(type, "end");
      }, txnChkTime * 60000);
    }, 5000);
  }

  StopChkStatus(intentType: string, type: string) {
    if (type === "end") {
      if (intentType === "QR") {
        this.showAmountPage = true;
        this.showQrPage = false;
        this.showPaymentLoader = false;
        this.alertService.error("QR Code expired, please generate a new one");
      } else {
        this.txnData = { txnStatus: "PENDING" } as any;
        this.txnDate = new Date();
        this.showAmountPage = false;
        this.showQrPage = false;
        this.showPaymentLoader = false;
        this.showTransactionResult = true;
      }
    }
    if (this.id)
      clearInterval(this.id);
    if (this.stsChkStart)
      clearTimeout(this.stsChkStart)
    if (this.stsChkStop)
      clearTimeout(this.stsChkStop)
  }

  goPrevious() {
    this.router.navigate([`/card-detail`, this.menu.id]);
  }

  goBack() {
    if (this.showAmountPage) {
      this.router.navigate([`/card-detail`, this.menu.id]);
    } else if (this.showQrPage) {
      this.showAmountPage = true;
      this.showQrPage = false;
    }
  }

  previousState() {
    if (this.showQrPage) {
      this.showAmountPage = true;
      this.showQrPage = false;
      this.StopChkStatus("", "");
    } else if (this.showTransactionResult) {
      this.showAmountPage = true;
      this.showTransactionResult = false;
    } else {
      window.history.back();
    }
  }

  ngOnDestroy() {
    if (this.id) {
      this.StopChkStatus("", "");
    }
  }

  getTxnStatus() {
    const reqObj = {
      merchantRequestId: this.merchantRequestId
    } as any;
    if (!this.isYESPAY && this.menu && this.menu.ypsorPrepaidProgram && this.menu.ypsorPrepaidProgram.programIdentifier) {
      reqObj.programId = this.menu.ypsorPrepaidProgram.programIdentifier;
    }
    this.adaptersService.txnStatus(reqObj).then((res: any) => {
      this.paymentConfirmationMessage = "Please don't close this window and stay on the same page while we automatically redirect you to the confirmation page.";
      if (res) {
        this.txnData = res;
        if (this.txnData) {
          if (this.txnData.txnStatus === 'FAIL'
            || (this.txnData.txnStatus === 'SUCCESS' && this.txnData.sorTxnStatus === 'SUCCESS')
            || (this.txnData.txnStatus === 'SUCCESS' && this.txnData.sorTxnStatus === 'FAILURE')) {
            if (this.isYESPAY) this.getUserData(); else this.fetchUserCardDetails();
            this.txnDate = new Date()
            this.showQrPage = false;
            this.showPaymentLoader = false
            this.showAmountPage = false;
            this.showTransactionResult = true;
            this.StopChkStatus("", "");
          }
        } else {
          // this.txnDate = new Date()
          // this.txnStatus = "SUCCESS"
          // this.showQrPage = false;
          // this.showTransactionResult = true;
          // clearInterval(this.id)
        }
      }
    }, (error: any) => {
      window.location.reload();
    })
  }

  getUserData() {
    var reqObj = {} as any;
    if (this.isYESPAY === false) {
      reqObj = { programId: this.menu.ypsorPrepaidProgram.programIdentifier }
    }
    this.adaptersService.dashboard(reqObj).then((res: any) => {
      if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
        this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true });
      } else {
        Globals.saveUser(res);
        Globals.userDataChangeEvent();
        this.userData = Globals.getUser();
        this.userData.walletBalance = this.userData.walletBalance.replace(/,/g, "");
        this.userData.remainingWalletBalanceLimit = this.userData.remainingWalletBalanceLimit.replace(/,/g, "");
      }
    });
  }

  copyText(value: string) {
    const selBox = this.renderer.createElement('textarea');

    this.renderer.setStyle(selBox, 'position', 'fixed');
    this.renderer.setStyle(selBox, 'left', '0');
    this.renderer.setStyle(selBox, 'top', '0');
    this.renderer.setStyle(selBox, 'opacity', '0');

    selBox.value = value;

    this.renderer.appendChild(this.elementRef.nativeElement, selBox);

    selBox.focus();
    selBox.select();

    // document.execCommand('copy');
    navigator.clipboard.writeText(value).then(() => {
      console.log('Text copied to clipboard successfully.');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });

    this.renderer.removeChild(this.elementRef.nativeElement, selBox);
    this.alertService.info("Text Copied to Clipboard");
  }

  goToAppStore() {
    window.open(this.downloadAPPIOSUrl, "blank")
  }

  dashboard() {
    var reqObj = {} as any;
    if (this.isYESPAY === false) {
      reqObj = { programId: this.menu.ypsorPrepaidProgram.programIdentifier }
    }
    this.adaptersService.dashboard(reqObj).then((res: any) => {
      if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
        setTimeout(() => { this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true }) }, 1000);
        this.goToCardDetail();
      } else {
        Globals.saveUser(res);
        Globals.userDataChangeEvent();
        this.userData = Globals.getUser();
        if (!this.userData) {
          Globals.setUserDataChangeListerner((user) => {
            this.userData = user;
            this.userData.walletBalance = this.userData.walletBalance.replace(/,/g, "")
            this.userData.remainingWalletBalanceLimit = this.userData.remainingWalletBalanceLimit.replace(/,/g, "")
            this.amountForm.get('amount').setValidators([Validators.required, Validators.max(Number(this.userData.remainingWalletBalanceLimit))]);
            Globals.removeUserDataChangeListerner();
          })
        } else {
          this.userData.walletBalance = this.userData.walletBalance.replace(/,/g, "")
          this.userData.remainingWalletBalanceLimit = this.userData.remainingWalletBalanceLimit.replace(/,/g, "")
        }
        const balanceLimit = this.userData ? Number(this.userData.remainingWalletBalanceLimit) : 10000
        this.amountForm.get('amount').setValidators([Validators.required, Validators.max(balanceLimit)]);
      }
    }, (err) => {
      this.goToCardDetail();
    });
  }

  getMenu(id: any) {
    this.adaptersService.menu({}).then((res: any) => {
      if (res && res.ypLiteWebMenus && res.ypLiteWebMenus.length > 0) {
        this.menuList = res.ypLiteWebMenus;
        const isObjectPresent = this.menuList.filter((o: any) => o.id == id);
        if (!isObjectPresent.length) this.goToHome();
        else this.setMenu(this.menuList, id);
      } else {
        this.menuList = [];
      }
    },
      (err) => {
        this.menuList = [];
        this.goToHome();
      });
  }

  setMenu(res: any, id: any) {
    if (res && res.length > 0) {
      for (let element of res) {
        if (element.id == id) {
          this.menu = element;
          Globals.setMenu(this.menu);
          this.setIsYesPayFlag();
          break;
        }
      }
    }
  }

  toggleModal() {
    this.addFundsModal = !this.addFundsModal;
  }

  goToHome() {
    this.router.navigate(['/menu']);
  }

  goToCardDetail() {
    this.router.navigate([`/card-detail`, this.menu.id]);
  }

  fetchUserCardDetails() {
    var data = {
      cardType: this.menu.ypsorPrepaidProgram.cardType,
      programId: this.menu.ypsorPrepaidProgram.programIdentifier
    }
    this.adaptersService.fetchUserCardDetails(data).then((res: any) => {
      this.handleUserCardDetailsResponse(res);
    },
      (err) => { }
    );
  }

  handleUserCardDetailsResponse(res: any) {
    if (res) {
      if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
        this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true });
      } else {

        Globals.setUserCardDetails(res);
        Globals.saveVCard(res.virtualCardResponse);
        Globals.savePCard(res.physicalCardResponse);

        this.initializeData();

      }
    }
  }

  initializeData() {
    this.virtualCardObj = Globals.getVCard();
    this.physicalCardObj = Globals.getPCard();

    this.getUserData();

    // if (this.menu && this.menu.ypsorPrepaidProgram) {
    //   if (this.menu.ypsorPrepaidProgram.cardType === 'VIRTUAL') {
    //     if (this.virtualCardObj) {
    //       this.virtualCardObj.walletBalance = this.virtualCardObj.walletBalance ? this.virtualCardObj.walletBalance.replace(/,/g, "") : 0;
    //       this.virtualCardObj.remainingWalletBalanceLimit = this.virtualCardObj.remainingWalletBalanceLimit ? this.virtualCardObj.remainingWalletBalanceLimit.replace(/,/g, "") : 0;
    //       this.amountForm.get('amount').setValidators([Validators.required, Validators.max(Number(this.virtualCardObj.remainingWalletBalanceLimit))]);
    //     }
    //   }
    //   if (this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL') {
    //     if (this.physicalCardObj) {
    //       this.physicalCardObj.cardBalance = this.physicalCardObj.cardBalance ? this.physicalCardObj.cardBalance.replace(/,/g, "") : 0;
    //       this.physicalCardObj.walletBalance = this.physicalCardObj.walletBalance ? this.physicalCardObj.walletBalance.replace(/,/g, "") : 0;
    //       this.physicalCardObj.remainingWalletBalanceLimit = this.physicalCardObj.remainingWalletBalanceLimit ? this.physicalCardObj.remainingWalletBalanceLimit.replace(/,/g, "") : 0;
    //       this.amountForm.get('amount').setValidators([Validators.required, Validators.max(Number(this.physicalCardObj.remainingWalletBalanceLimit))]);
    //     };
    //   }
    // }
  }

}
