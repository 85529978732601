import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import { AlertService } from '../app/_alert';
import JSEncrypt from 'jsencrypt';
import { cipher, util } from 'node-forge';
import { DataService } from './data.service';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public options = {
    autoClose: true,
    keepAfterRouteChange: true
  }

  inactivityPeriod: any;
  timeLeft: any;
  interval: any;

  constructor(
    private router: Router,
    public dataService: DataService,
    public alertService: AlertService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    
    const key = CryptoJS.lib.WordArray.random(16).toString();
    const iv = CryptoJS.lib.WordArray.random(8).toString();
    const isMultipart = request.body instanceof FormData;
    
    this.printBody(request.body);
    
    if (!isMultipart) {
      if (request.url && request.url.includes('services')) {
        const encryptedRequest = this.crAesGcmEncrypt(JSON.stringify(request.body, null, 4), key, iv);
        request = request.clone({
          body: {
            body: encryptedRequest.body
          },
          headers: this.returnHeaders(request, encryptedRequest)
        });
      }
    }
  
    return next.handle(request)
      .pipe(
        map(event => {
          if (event instanceof HttpResponse) {
            if (event.headers.has('Content-Type') && event.headers.get('Content-Type').includes('application/json')) {
              let temp;
              if (typeof event.body !== 'object') temp = JSON.parse(event.body).body;
              else temp = event.body.body;
              const decryptedText = this.crAesGcmDecrypt(temp, key, iv);
              if (decryptedText) {
                this.printBody(decryptedText);
                event = event.clone({
                  body: typeof event.body !== 'object' ? decryptedText : JSON.parse(decryptedText)
                });
              }
            }
            return event;
          }
        }),
        tap(evt => {
          if (evt instanceof HttpResponse) {
            let localUserObj: any = JSON.parse(localStorage.getItem("userObj")) || {};
            this.inactivityPeriod = parseInt(JSON.parse(localStorage.getItem("inactivityPeriod"))) || null;

            if (evt.url && evt.url.includes('/cobank/yespay-lite/verify-otp') && evt.body && evt.body.responseStatus === 'SUCCESS') {
              localStorage.setItem('inactivityPeriod', JSON.stringify(evt.body.responseBody.inactivityPeriod));
              this.inactivityPeriod = this.timeLeft = evt.body.responseBody.inactivityPeriod || 0;
              this.startTimer(this.inactivityPeriod);
            } else if (!evt.url.includes('/get-otp') && !evt.url.includes('/cobank/yespay-lite/verify-otp')) {
              if (this.inactivityPeriod) {
                this.resetTimer(this.inactivityPeriod);
                this.startTimer(this.inactivityPeriod);
              } else {
                this.inactivityPeriod = 0;
                this.resetTimer(this.inactivityPeriod);
                this.startTimer(this.inactivityPeriod);
              }
            }

            const refreshToken = evt.headers.get('Authorization');
            let tokenWithBearer = '';
            if (refreshToken) tokenWithBearer = refreshToken.slice(0, 7);

            if (refreshToken && tokenWithBearer === 'Bearer ') {
              const jwt = refreshToken.slice(7, refreshToken.length);
              localUserObj.token = jwt;
              localStorage.setItem('userObj', JSON.stringify(localUserObj));
            } else if (refreshToken) {
              localUserObj.token = refreshToken;
              localStorage.setItem('userObj', JSON.stringify(localUserObj));
            }
          }
        }),
        timeout(40000), // 40000ms = 40s
        catchError((error: HttpErrorResponse) => {
          let maxAttempts: any = ''
          const errorObj: any = error;
          let decryptedErrorBody: any

          if (!(request.body instanceof FormData) && error.url.includes('services')) {
            const shouldDecrypt = ![401, 500].includes(error.status);
            if (shouldDecrypt) {
              if (error.error && error.error.body) {
                decryptedErrorBody = typeof error.error.body === 'object'
                  ? this.crAesGcmDecrypt(error.error.body, key, iv)
                  : JSON.parse(this.crAesGcmDecrypt(error.error.body, key, iv));
                errorObj.error = decryptedErrorBody;
              }
            }
          }
          
          if (error.url && error.url.includes('api/logout')) {
            if (this.interval) clearInterval(this.interval);
            error = null;
          }
          if (error) {
            if (error.message === 'Timeout has occurred' || error.status == 504) this.alertService.error('Request timed out. Please check the status of request after sometime.', this.options);
            else if (error.status == 401) this.alertService.error("Login session expired, please re-login!", this.options);
            else if (error.error && error.error.title && error.error.title != '') this.alertService.error(error.error.title, this.options);
            else if (error.error && error.error.message && error.error.message != '') {
              maxAttempts = error.error.message.includes("left before your account is locked");
              if (!maxAttempts) this.alertService.error(error.error.message, this.options);
            }
            if (error.error && error.error.errorMessage) this.alertService.error(error.error.errorMessage, this.options);
          }
          if (errorObj) {
            return throwError(errorObj);
          } else {
            return throwError(error);
          }
        })
      );
  }

  startTimer(inactivityPeriod) {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        // console.log('Inactivity period left: ' + this.timeLeft);
        this.timeLeft -= 1;
      } else {
        // setTimeout(() => { this.alertService.error("Session Expired", this.options) }, 1000);
        console.log('Maximmum inactivity period allowed: ' + inactivityPeriod);
        console.log('Inactivity period left: ' + this.timeLeft);
        clearInterval(this.interval);
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    }, 1000);
  }

  resetTimer(inactivityPeriod) {
    clearInterval(this.interval);
    this.timeLeft = inactivityPeriod;
  }

  crAesGcmEncrypt(reqBody: any, reqKey: any, reqIv: any) {
    const buffer = util.createBuffer(reqBody, 'utf8');
    const cipherGCM = cipher.createCipher('AES-GCM', reqKey);
    cipherGCM.start({
      iv: reqIv, // should be a 12-byte binary-encoded string or byte buffer
      tagLength: 128 // optional, defaults to 128 bits
    });
    cipherGCM.update(buffer);
    cipherGCM.finish();

    const encryptedData = cipherGCM.output.data;
    const authenticatedTag = cipherGCM.mode.tag.data;
    const encryptedBytes = util.createBuffer();
    encryptedBytes.putBytes(encryptedData);
    encryptedBytes.putBytes(authenticatedTag);

    const encodedEncryptedData = util.encode64(encryptedData + authenticatedTag);
    const encryptedkey = this.rsaEncrypt(reqKey);
    const encryptedIv = this.rsaEncrypt(reqIv);

    return {
      body: encodedEncryptedData,
      iv: encryptedIv,
      key: encryptedkey
    };
  }

  rsaEncrypt(data: any) {
    const publicKey = this.dataService.getpublicKey();
    const encryptAlgo = new JSEncrypt({});
    encryptAlgo.setPublicKey(publicKey);
    const encrypted = encryptAlgo.encrypt(data);
    return encrypted;
  }

  crAesGcmDecrypt(resBody: any, resKey: any, resIv: any) {
    if (!resBody) return;
    else if (resBody.title || resBody.message) {
      return resBody;
    } else {
      const decodedData = util.decode64(resBody);
      const encryptedData = decodedData.slice(0, decodedData.length - 16);
      const authenticatedTag = decodedData.slice(decodedData.length - 16, decodedData.length);
      const buffer = util.createBuffer(encryptedData);
      const decipher = cipher.createDecipher('AES-GCM', resKey);

      decipher.start({
        iv: resIv,
        tag: authenticatedTag as any // authentication tag from encryption
      });
      decipher.update(buffer);
      const pass = decipher.finish();
      if (pass) {
        const bytes = decipher.output.getBytes();
        const decryptedText = util.decodeUtf8(bytes);
        return decryptedText;
      }
    }
  }

  returnHeaders(request: any, encryptedRequest: any) {
    let headers: HttpHeaders = new HttpHeaders();

    // Append existing headers
    request.headers.keys().forEach(header => {
      const value = request.headers.get(header);
      headers = headers.append(header, value);
    });

    // Append new headers 'iv', 'key', and 'Client'
    headers = headers.append('iv', encryptedRequest.iv.toString());
    headers = headers.append('key', encryptedRequest.key.toString());
    headers = headers.append('Client', 'web');

    return headers;
  }

  printBody(decryptedText: any) {
    // eslint-disable-next-line no-console
    // console.log(decryptedText); // to be commented on production
  }

}
