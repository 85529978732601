import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MaskPipe } from '../../app/filters/mask.pipe';
import { AlertService } from '../_alert';
import { AdaptersService } from '../adapters.service';
import { Globals } from '../global.constants';

@Component({
  selector: 'app-card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.scss'],
  providers: [DatePipe, MaskPipe]
})
export class CardDetailComponent implements OnInit, OnDestroy {

  virtualCardObj: any;
  physicalCardObj: any;

  moment: any = moment;

  menu: any;
  params: any;
  isYESPAY: any;
  userData: any;
  maskKitNo: any;
  errorMessage: any;
  userCardDetails: any;
  keychainLinkStatus: any;

  hideCvv = true;
  isLoading = false;
  isReplace = false;
  isTxnLoading = true;
  fetchingCvv = false;
  linkKeychain = false;
  showAlertMsg = false;
  addFundsModal = false;
  cardBalanceInfo = false;
  isAndroidMobile = false;

  isYesPayApiLoading: boolean;
  isNonYesPayApiLoading: boolean;
  isNonYesPayApiFailed: boolean = false;
  isYesPayApiFailed: boolean = false;
  isTxnApiFailed: boolean = false;

  alertMsg = '';
  alertTitle = '';
  decryptedCvv: string;

  txnsList = [];
  menuList: any = [];
  txnTypes: string[] = ['UPI_PPI_INWARD',
    'UPI_PPI_OUTWARD',
    'BBPS',
    'COBANK_UPI_LOAD',
    'RECHARGE',
    'MERCHANT',
    'E_COLLECT',
    'FastagRetail_Load',
    'SendMoney',
    'Bank Transfer',
    'FEERATE',
    'ECOMMERCE',
    'RequestMoney',
    'PAYU',
    'UPI',
    'CC',
    'CBS',
    'CASHBACK',
    'DEAL',
    'Dutch',
    'Non App Transfer',
    'TEMP'
  ];
  txnTypesForWalletBalance: string[] = ['UPI_PPI_INWARD', 'E_COLLECT', 'ECOMMERCE', 'RequestMoney', 'PAYU', 'UPI', 'CC', 'CBS', 'DEAL', 'Dutch'];

  cardStatusList = ['active', 'allocated', 'replaced'];
  cardSuccessStatus = ['active', 'allocated', 'locked', 'replaced'];

  keyChainForm: UntypedFormGroup = this.formBuilder.group({
    oldKitNo: [null],
    kitNo: ["", [Validators.required]],
    validationNo: ["", [Validators.required, Validators.pattern("[0-9]{4}$")]]
  });

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private maskPipe: MaskPipe,
    public alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    protected activatedRoute: ActivatedRoute,
    private adaptersService: AdaptersService,
    protected renderer: Renderer2,
    protected elementRef: ElementRef
  ) {
    this.isAndroidMobile = Globals.isAndroidMobile();
  }

  ngOnDestroy() {
    Globals.updatePhyCardFlag = false;
  }

  ngOnInit() {
    this.menu = Globals.getMenu();
    this.userCardDetails = Globals.getUserCardDetails();

    this.activatedRoute.params.subscribe(params => {
      this.params = params;
    });

    // If menu object is empty or on page refresh
    if (!this.menu) {
      if (this.params.id) {
        this.isLoading = true;
        this.getMenu(this.params.id);
      }
    }

    // Navigated from menu page
    else {
      this.setIsYesPayFlag();
    }

  }

  callAllAPIs(flag: boolean) {
    if (flag) this.getTransactions();
    if (this.isYESPAY) this.dashboard();
    else if (!this.isYESPAY) this.fetchUserCardDetails();
  }

  callAPIs(flag: boolean) {

    Globals.reFreshCards = true;

    // Existing Flow
    if (this.isYESPAY) {
      this.dashboard();
    }

    // Fetch User Card Details
    else if (!this.isYESPAY) {
      if (!this.userCardDetails) this.fetchUserCardDetails();
      else this.handleUserCardDetailsResponse(this.userCardDetails);
    }

    if (flag) {
      this.getTransactions();
    }

  }

  setIsYesPayFlag() {
    if (this.menu.menuIdentifier === 'YESPAY') this.isYESPAY = true; else this.isYESPAY = false;
    this.callAPIs(true);
  }

  // flag to setBalance in non-yespay flow
  initializeData(flag: boolean) {
    this.virtualCardObj = Globals.getVCard();
    this.physicalCardObj = Globals.getPCard();
    if (flag) {
      if (this.virtualCardObj && this.virtualCardObj.walletBalance) this.virtualCardObj.walletBalance = this.virtualCardObj.walletBalance.replace(/,/g, "");
      if (this.physicalCardObj && this.physicalCardObj.walletBalance) this.physicalCardObj.walletBalance = this.physicalCardObj.walletBalance.replace(/,/g, "");
      if (this.physicalCardObj && this.physicalCardObj.cardBalance) this.physicalCardObj.cardBalance = this.physicalCardObj.cardBalance.replace(/,/g, "");
    }
  }

  getVirtualCard() {
    this.isYesPayApiLoading = true;
    this.isYesPayApiFailed = false;
    if (!this.virtualCardObj || Globals.reFreshCards) {
      this.adaptersService.virtualCard({}).then((res: any) => {

        if (res) {

          this.virtualCardObj = res;

          try {
            Globals.saveVCard(this.virtualCardObj);

            if (this.userData && this.userData.isKeyChainUser) {
              this.getPhysicalCard();
            } else {
              Globals.reFreshCards = false;
            }
          } catch (error) {
            this.isYesPayApiFailed = true;
          }

          this.isYesPayApiLoading = false;

        } else {
          this.isYesPayApiLoading = false;
          this.isYesPayApiFailed = true;
        }
      },
        (err) => {
          this.isYesPayApiLoading = false;
          this.isYesPayApiFailed = true;
        });
    } else {
      if (this.virtualCardObj && this.virtualCardObj.isKeyChainUser) {
        this.getPhysicalCard()
      } else {
        this.isYesPayApiLoading = false;
      }
    }
  }

  getPhysicalCard() {
    this.isYesPayApiLoading = true;
    if (!this.physicalCardObj || Globals.reFreshCards) {
      this.adaptersService.physicalCard({}).then((res: any) => {

        Globals.reFreshCards = false;

        if (res) {
          if (Globals.updatePhyCardFlag) {
            this.physicalCardObj = res;
            Globals.savePCard(res);
          } else {
            console.log('Page was redirected before api/physical-card response was received.');
          }
        } else {
          this.isYesPayApiFailed = true;
        }

        this.isYesPayApiLoading = false;
      },
        (err) => {
          this.isYesPayApiLoading = false;
          this.isYesPayApiFailed = true;
        });
    } else {
      this.isYesPayApiLoading = false;
    }
  }

  filterTxnType(filteredArr) {
    return filteredArr.transaction_type.toUpperCase() !== 'POINTS'
  }

  getTransactions() {

    this.isTxnApiFailed = false;
    this.isTxnLoading = true;

    let reqObj = {
      "startDate": this.datePipe.transform(new Date(new Date().setFullYear(new Date().getFullYear() - 10)), 'dd-MM-yyyy'),
      "endDate": this.datePipe.transform(new Date(), 'dd-MM-yyyy'),
      "page": "1",
    } as any;

    if (!this.isYESPAY && this.menu && this.menu.ypsorPrepaidProgram && this.menu.ypsorPrepaidProgram.programIdentifier) {
      reqObj.programId = this.menu.ypsorPrepaidProgram.programIdentifier;
    }
    if (!this.isYESPAY && !reqObj.programId) {
      this.isTxnApiFailed = true;
      this.isTxnLoading = false;
      return;
    }

    this.adaptersService.fetchTxns(reqObj).then((res: any) => {
      if (res) {
        if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
          this.isTxnApiFailed = true;
          this.isTxnLoading = false;
          return;
        }
        else {
          if (res && res.transactions.length > 0) {
            this.txnsList = res.transactions.filter(this.filterTxnType);
            this.txnsList.forEach((item: any) => {
              if (item.transaction_tag !== null) {
                try {
                  if (typeof item.transaction_tag === 'string')
                    item.transaction_tag = JSON.parse(item.transaction_tag);
                } catch (e) {
                  const merchantDetails = item.transaction_tag.split("|")
                  if (merchantDetails.length > 2) {
                    item.transaction_tag = { "Merchant_Name": merchantDetails[2], "Txn_Type": merchantDetails[0] }
                  } else {
                    item.transaction_tag = {} as any;
                  }
                }
              } else {
                item.transaction_tag = {} as any;
              }
            })
          }
          this.isTxnLoading = false;
        }
      }
    },
      (err) => {
        this.isTxnApiFailed = true;
        this.isTxnLoading = false;
      }
    );
  }

  showCvv() {
    this.fetchingCvv = true;
    if (!this.decryptedCvv) {
      try {
        if (this.isYESPAY) this.decryptedCvv = this.virtualCardObj.cvv;
        else {
          if (this.menu.ypsorPrepaidProgram.cardType === 'VIRTUAL') this.decryptedCvv = this.virtualCardObj.cvv;
          else this.decryptedCvv = this.physicalCardObj.cvv;
        }
      }
      catch (error: any) {
        setTimeout(() => {
          // console.log(error);
          this.alertService.error('Unable to fetch CVV. Please try again later.');
          this.fetchingCvv = false;
        }, 600);
        return;
      }
    }
    setTimeout(() => {
      this.fetchingCvv = false;
    }, 600);
    this.hideCvv = !this.hideCvv;
  }

  copyCardNumber() {
    const selBox = this.renderer.createElement('textarea');

    this.renderer.setStyle(selBox, 'position', 'fixed');
    this.renderer.setStyle(selBox, 'left', '0');
    this.renderer.setStyle(selBox, 'top', '0');
    this.renderer.setStyle(selBox, 'opacity', '0');

    if (this.isYESPAY) selBox.value = this.virtualCardObj.cardNumber;
    if (!this.isYESPAY) {
      this.menu.ypsorPrepaidProgram.cardType === 'VIRTUAL' ? selBox.value = this.virtualCardObj.cardNumber : this.physicalCardObj.cardNumber;
    }

    this.renderer.appendChild(this.elementRef.nativeElement, selBox);

    selBox.focus();
    selBox.select();

    // document.execCommand('copy');
    navigator.clipboard.writeText(selBox.value).then(() => {
      console.log('Text copied to clipboard successfully.');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });

    this.renderer.removeChild(this.elementRef.nativeElement, selBox);
    
    this.alertService.success("Card Number Copied to Clipboard");
  }

  goToAddfunds() {
    // YPP-18498 - Temporarily enabled the functionality of Adding Funds due to production issue.
    this.router.navigate(['/add-funds', this.menu.id]);

    // this.addFundsModal = !this.addFundsModal;
  }

  goToSettings() {
    this.router.navigate([`/settings`, this.menu.id]);
  }

  goToTransactionPage() {
    if (this.txnsList && this.txnsList.length > 5) {
      this.router.navigate(['/card-transaction', this.menu.id]);
    }
  }

  toggleKeychainModal() {
    this.errorMessage = null;
    if (this.physicalCardObj && this.physicalCardObj.cardStatus === 'BLOCKED') {
      this.isReplace = true;
    }
    this.alertTitle = this.isReplace ? 'Replace Keychain' : 'Link Keychain';
    this.linkKeychain = !this.linkKeychain;
    this.isLoading = false;
    this.showAlertMsg = false;
    this.keyChainForm.reset();
  }

  linkKeyChain() {
    this.errorMessage = null;
    this.keyChainForm.markAllAsTouched();
    if (this.keyChainForm.valid) {
      this.isLoading = true;
      if (this.isReplace) {
        const reqObj = {
          oldKitNumber: this.keyChainForm.value.oldKitNo,
          newKitNumber: this.keyChainForm.value.kitNo,
          validationNumber: this.keyChainForm.value.validationNo
        }
        this.adaptersService.replaceCard(reqObj).then((res: any) => {
          if (res) {
            if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
              this.errorMessage = res.responseMessage;
            }
            else {
              this.showKeyChainMsg(true);
              this.physicalCardObj = null;
              this.getPhysicalCard();
            }
            this.isLoading = false;
          }
        }, (err: any) => {
          this.isLoading = false;
        });
      } else {
        const reqObj = {
          kitNumber: this.keyChainForm.value.kitNo,
          validationNumber: this.keyChainForm.value.validationNo
        }
        this.adaptersService.linkKeyChain(reqObj).then((res: any) => {
          if (res) {
            if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
              this.errorMessage = res.responseMessage;
            } else {
              this.showKeyChainMsg(true)
              this.physicalCardObj = null;
              this.getPhysicalCard();
              this.userData.isKeyChainUser = true;
              Globals.saveUser(this.userData);
            }
          } else {
            this.toggleKeychainModal();
          }
          this.isLoading = false;
        }, (error: any) => {
          this.isLoading = false;
        })
      }
    }
  }

  showKeyChainMsg(status: boolean) {
    this.keychainLinkStatus = status;
    this.isLoading = false;
    this.showAlertMsg = true;
    this.linkKeychain = true;
    this.maskKitNo = this.maskPipe.transform(this.keyChainForm.value.kitNo, "4", "*", "4");
    this.alertTitle = this.keychainLinkStatus ? "Keychain Linked Successfully!" : "Keychain Linking Failed";
    this.alertMsg = this.keychainLinkStatus ? 'Your keychain with Kit no. ' + this.maskKitNo + ' has been linked successfully. For transactions above Rs. 5000, you will be asked to enter a PIN. Would you like to set it now?' : 'Your keychain with Kit no. ' + this.maskKitNo + ' was not linked due to technical error. Please try again after some time.';
  }

  validateKeyChainInputs(input: any) {
    input.blur();
    input.focus();
    this.errorMessage = '';
  }

  getDisplayDetail(item: any) {
    item.displayDetail = !item.displayDetail;
  }

  gotoSetPinPage() {
    this.linkKeychain = false;
    const reqObj = {} as any;
    if (this.menu && this.menu.ypsorPrepaidProgram) {
      if (this.menu.ypsorPrepaidProgram.programIdentifier) {
        reqObj.programId = this.menu.ypsorPrepaidProgram.programIdentifier
      }
      if (this.physicalCardObj && this.physicalCardObj.kitNumber) reqObj.kitNumber = this.physicalCardObj.kitNumber;
    }
    this.adaptersService.setPin(reqObj).then((response: any) => {
      if (response && response.url && this.menu.ypsorPrepaidProgram) {
        const responseObj = {
          url: response.url,
          cardNumber: !this.isYESPAY ? this.physicalCardObj.cardNumber : this.virtualCardObj.cardNumber
        }
        const responseString = responseObj ? JSON.stringify(responseObj) : '';
        this.router.navigate(['/set-pin', this.menu.id], { queryParams: { responseString }, skipLocationChange: true });
      }
    }), (error: any) => {

    };
  }

  dashboard() {
    this.isYesPayApiLoading = true;
    Globals.updatePhyCardFlag = true;
    var reqObj = {} as any;
    if (this.isYESPAY === false) {
      reqObj = { programId: this.menu.ypsorPrepaidProgram.programIdentifier }
    }
    this.adaptersService.dashboard({}).then((res: any) => {

      if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
        this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true });
        this.isYesPayApiLoading = false;
        this.isYesPayApiFailed = true;
      }

      else {

        Globals.saveUser(res);
        Globals.userDataChangeEvent();
        this.userData = Globals.getUser();

        if (!this.userData) {
          Globals.setUserDataChangeListerner((user) => {
            this.userData = user;
            this.setBalance(true);
            this.getVirtualCard();
          })
        }

        else {
          this.setBalance(false);
          this.getVirtualCard();
        }

        this.initializeData(false);

      }
    }, (err) => {
      this.isYesPayApiLoading = false;
      this.isYesPayApiFailed = true;
    });
  }

  // flag for removeUserDataChangeListerner
  setBalance(flag: boolean) {
    this.userData.walletBalance = this.userData.walletBalance.replace(/,/g, "");
    this.userData.remainingWalletBalanceLimit = this.userData.remainingWalletBalanceLimit.replace(/,/g, "");
    if (flag) Globals.removeUserDataChangeListerner();
  }

  getMenu(id: any) {
    this.adaptersService.menu({}).then((res: any) => {
      if (res && res.ypLiteWebMenus && res.ypLiteWebMenus.length > 0) {
        this.menuList = res.ypLiteWebMenus;
        const isObjectPresent = this.menuList.filter((o: any) => o.id == id);
        if (!isObjectPresent.length) this.goToHome();
        else this.setMenu(this.menuList, id);
      } else {
        this.menuList = [];
      }
    },
      (err) => {
        this.menuList = [];
        this.goToHome();
      });
  }

  setMenu(res: any, id: any) {
    if (res && res.length > 0) {
      for (let element of res) {
        if (element.id == id) {
          this.menu = element;
          Globals.setMenu(this.menu);
          this.setIsYesPayFlag();
          break;
        }
      }
    }
  }

  toggleCardBalanceInfo() {
    this.cardBalanceInfo = !this.cardBalanceInfo;
  }

  goToHome() {
    this.router.navigate(['/menu']);
  }

  fetchUserCardDetails() {
    this.isNonYesPayApiLoading = true;
    this.isNonYesPayApiFailed = false;
    var data = {
      cardType: this.menu.ypsorPrepaidProgram.cardType,
      programId: this.menu.ypsorPrepaidProgram.programIdentifier,
    };
    this.adaptersService.fetchUserCardDetails(data).then(
      (res: any) => {
        this.handleUserCardDetailsResponse(res);
      },
      (err) => {
        this.isNonYesPayApiFailed = true;
        this.isNonYesPayApiLoading = false;
      }
    );
  }

  handleUserCardDetailsResponse(res: any) {
    if (res) {
      if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {

        // User is BLOCKED
        if (res.responseCode === 'CB33') {
          setTimeout(() => { this.alertService.error("For security reasons, login through this number is restricted. Please try with a different mobile number.", { autoClose: true, keepAfterRouteChange: true }) }, 1000);
          this.goToHome();
        }

        else {
          // this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true });
          this.isNonYesPayApiLoading = false;
          this.isNonYesPayApiFailed = true;
        }

      } else {

        if (res) {
          Globals.setUserCardDetails(res);
          Globals.saveVCard(res.virtualCardResponse);
          Globals.savePCard(res.physicalCardResponse);
        }

        // If user not found
        if (res && !res.isPpiUserPresent) {
          this.router.navigate([`/card`, this.menu.id]);
        }

        // If user found and user status is ACTIVE - Check if VIRTUAL or PHYSICAL
        else if (res && res.isPpiUserPresent) {
          if (this.menu.ypsorPrepaidProgram.cardType === 'VIRTUAL') {
            this.setData(res);
          }
          else if (this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL') {

            // If cardStatus is ACTIVE, REPLACED or ALLOCATED
            if (res.physicalCardResponse && res.physicalCardResponse.cardStatus && this.cardSuccessStatus.includes(res.physicalCardResponse.cardStatus.toLowerCase())) {
              this.setData(res);
            }

            // If cardStatus is BLOCKED - isReplaceCard: true
            else if (res.physicalCardResponse && res.isReplaceCard) {
              this.router.navigate([`/card`, this.menu.id]);
            }

            // If NO card details are present
            else if (!res.physicalCardResponse && res.isActivateCard) {
              this.router.navigate([`/card`, this.menu.id]);
            }
          }
        }

      }
    } else {
      this.goToHome();
    }
  }

  setData(res: any) {
    Globals.saveVCard(res.virtualCardResponse);
    Globals.savePCard(res.physicalCardResponse);
    this.isNonYesPayApiLoading = false;
    this.initializeData(true);
  }

}
